<template>
  <div>
    <!-- TEMPLATE EVENTOS -->
    <div class="cardDetails">
      <!-- HEADER -->
      <a-card class="title">
        <a-row :gutter="24">
          <a-col :span="19">
            {{ offerDetails.name }} {{ offerDetails.title }}
          </a-col>
          <a-col :span="5" style="text-align: right;">
            <a-tag :color="color">{{ translateType(offerType) }}</a-tag>
          </a-col>
        </a-row>
      </a-card>

      <a-row class="content-grid" type="flex" :gutter="24">
        <!-- COLUNA DA ESQUERDA -->
        <a-col class="col-session">
          <div class="card-img">
            <img :src="offerDetails.banner['1x1']
              ? offerDetails.banner['1x1']
              : 'https://lp-js-libs.s3.sa-east-1.amazonaws.com/assets/Plataforma/sem-imagem.jpg'
              " alt="Imagem do Conteudo" />
          </div>
          <div class="action">
            <div class="share">
              <div class="action-title">
                <h5>{{ offerDetails.checkoutLink || 'Curso' }}</h5>
              </div>
              <svg class="action-icon" style="margin-top: 4px;" width="20" height="20" fill="none" stroke="#ffffff"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg" @click="copyText">
                <rect width="13" height="13" x="9" y="9" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            </div>
            <div class="favorite" @click="toggleFavorite(offerType, offerDetails.id)">
              <svg class="action-icon" width="20" height="20" :fill="isFavorite ? 'red' : 'none'"
                :stroke="isFavorite ? 'red' : '#ffffff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                </path>
              </svg>
            </div>
            <div v-if="offerType === 'Event'" class="favorite" @click="mostrarDrawer">
              <svg class="action-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF"
                viewBox="0 0 576 512">
                <path
                  d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
              </svg>
            </div>

          </div>
          <WidgetIndication :visible="drawerVisivel" :association="this.$route.params.offerId"
            :offerLink="offerDetails.checkoutLink" @fecharDrawer="fecharDrawer" />
        </a-col>

        <!-- COLUNA DA DIREITA EVENTOS -->
        <a-col class="col-session" v-if="offerType === 'Event'">
          <div class="infos">
            <div class="icon">
              <svg width="25" height="25" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <path d="M12 7a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"></path>
              </svg>
            </div>
            <div class="text">
              <div class="headerTitle">{{ formatDate(offerDetails.date) }}</div>
              <p>{{ offerDetails.address }}</p>
            </div>
          </div>

          <a-card class="info">
            <p>Formato</p>
            <h5>{{ translateFormat(offerDetails.format) }}</h5>
          </a-card>

          <a-card class="info">
            <p>Público Alvo</p>
            <h5>...</h5>
          </a-card>

          <div class="price">
            <div>
              <div class="headerTitle">Preços:</div>
              <p>Apartir de</p>
            </div>
            <div class="value"><span>R$</span> {{ offerDetails.price }}</div>
          </div>
          <a href="#">
            <div class="buttonStart" @click="buyContent">Comprar Agora</div>
          </a>
        </a-col>

        <!-- COLUNA DA DIREITA PRODUTOS -->
        <a-col class="col-session" v-if="offerType === 'Product'">
          <div class="infos">
            <div class="icon">
              <svg width="25" height="25" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <path d="M12 7a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"></path>
              </svg>
            </div>
            <div class="text">
              <div class="headerTitle">{{ formatDate(offerDetails.date) }}</div>
              <p>{{ offerDetails.address }}</p>
            </div>
          </div>

          <a-card class="info">
            <p>Formato</p>
            <h5>{{ translateFormat(offerDetails.format) }}</h5>
          </a-card>

          <div class="price">
            <div>
              <div class="headerTitle">Preços:</div>
              <p>Apartir de</p>
            </div>
            <div class="value"><span>R$</span> {{ offerDetails.price }}</div>
          </div>
        </a-col>

        <!-- COLUNA DA DIREITA CURSOS -->
        <a-col class="col-session" v-if="offerType === 'Course'">
          <div class="grid-infos">
            <div class="info-course">
              <svg width="30" height="30" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
                <path d="M12 6v6l4 2"></path>
              </svg>
              <div>
                <p>Carga Horária</p>
                <h5>10 Horas</h5>
              </div>
            </div>
            <div class="info-course">
              <svg width="30" height="30" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <path d="M9 3a4 4 0 1 0 0 8 4 4 0 1 0 0-8z"></path>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <div>
                <p>Alunos (As)</p>
                <h5>3.367</h5>
              </div>
            </div>
            <div class="info-course">
              <svg width="30" height="30" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3">
                </path>
              </svg>
              <div>
                <p>Avaliação</p>
                <h5>9.3</h5>
              </div>
            </div>
            <div class="info-course">
              <svg width="30" height="30" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8v4l3 3"></path>
                <path d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
              </svg>
              <div>
                <p>Atualizado Em</p>
                <h5>28/09/2023</h5>
              </div>
            </div>
          </div>

          <div class="price">
            <div>
              <div class="headerTitle">Preços:</div>
              <p>Apartir de</p>
            </div>
            <div class="value"><span>R$</span> {{ offerDetails.price }}</div>
          </div>
          <router-link :to="'/play/' + offerDetails.id">
            <a href="#">
              <div class="buttonStart" @click="buyContent">Iniciar Curso</div>
            </a>
          </router-link>
        </a-col>
      </a-row>

      <!-- DESCRIÇÃO DO CONTEUDO -->
      <a-row style="margin-top: 1rem; margin-bottom: 5rem;">
        <a-card>
          <h5>Descrição</h5>
          <p>{{ offerDetails.description }}</p>
        </a-card>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import WidgetIndication from '../../../components/Widgets/WidgetIndication.vue';
import axios from 'axios';

export default {
  components: {
    WidgetIndication,
  },
  data() {
    return {
      drawerVisivel: false,
      isFavorite: false,
      offerDetails: null,
      offerType: "",
      color: '#f50',
      bookmarks: [],
      favURL: "",
    };
  },
  created() {
    const offerId = this.$route.params.offerId;
    this.offerType = this.$route.params.offerType;

    this.getOfferDetails(offerId, this.offerType);
    this.getFavorite()
    this.teste()
  },
  methods: {
    mostrarDrawer() {
      this.drawerVisivel = true;
    },
    fecharDrawer() {
      this.drawerVisivel = false;
    },
    formatDate(date) {
      // Verifique se a data está no formato esperado
      const isValidDate = moment(date, 'YYYY-MM-DD', true).isValid();

      if (isValidDate) {
        // Formate a data para o novo formato 'DD-MM-YYYY'
        return moment(date).format('DD/MM/YYYY');
      } else {
        // Se a data não estiver no formato esperado, retorne a data original
        return date;
      }
    },
    /*  toggleFavorite() {
       this.isFavorite = !this.isFavorite;
 
       const offerId = this.$route.params.offerId;
       const favoriteOffers = JSON.parse(localStorage.getItem('favoriteOffers')) || [];
 
       if (this.isFavorite) {
         // Adicione o ID da oferta aos favoritos no localStorage
         favoriteOffers.push(offerId);
 
         // var placement = 'bottomLeft';
 
         // this.$notification.open({
         //   message: `Nova Meta!`,
         //   description:
         //     'Esse conteúdo foi adicionado às suas metas',
         //   placement,
         // });
 
         this.$message.success('Esse conteúdo foi adicionado às suas metas!');
 
       } else {
         // Remova o ID da oferta dos favoritos no localStorage
         const index = favoriteOffers.indexOf(offerId);
         if (index !== -1) {
           favoriteOffers.splice(index, 1);
         }
       }
 
       // Atualize o localStorage
       localStorage.setItem('favoriteOffers', JSON.stringify(favoriteOffers));
     }, */
    translateFormat(type) {
      switch (type) {

        case "PRESENTIAL":
          return "Presencial"
        case "ONLINE":
          return "Online"
        default:
          return "Híbrido"
      }

    },
    translateType(type) {
      switch (type) {

        case 'Course':
          this.color = '#607e01';
          return 'CURSO'
        case 'Event':
          this.color = '#1ad8ff';
          return 'EVENTO'
        default:
          return 'CONTEÚDO'
      }

    },
    getOfferDetails(offerId, offerType) {
      let pathURL = "";

      switch (offerType) {
        case "Course":
          pathURL = `/course/${offerId}`;
          break;
        case "Event":
          pathURL = `/event/${offerId}`;
          break;
        case "Product":
          pathURL = `/products/${offerId}`;
          break;
        default:
          console.error("Tipo de oferta desconhecido:", offerType);
          return;
      }

      this.$api.dataService
        .getData(pathURL)
        .then((res) => {
          console.log(res);
          this.offerDetails = res;
        })
        .catch((error) => {
          console.error("Erro ao obter detalhes da oferta:", error);
        });
    },
    buyContent() {

      console.log("entrou em curso");

      const id = this.$route.params.offerId;
      const type = this.$route.params.offerType;

      if (type == 'Event') {
        //pega o link do evento e redireciona
        const url = 'https://www.exemplo.com';
        // Abre a URL em uma nova guia
        window.open(url, '_blank');

      } else {

        console.log("teste");

        // this.$api.dataService
        // .save(`${type}/buy/${id}`, null, null)
        // .then((res) => {

        // 	}
        // )
        // .catch((error) => {
        // 	console.error("Erro ao comprar a oferta:", error);
        // })

      }



    },
    copyText() {
      const textToCopy = this.offerDetails.checkoutLink || 'Curso';

      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);

      textArea.select();
      document.execCommand('copy');

      document.body.removeChild(textArea);

      this.$message.success('Texto copiado para a área de transferência!');
    },
    getFavorite() {
  this.$api.dataService
    .getData("/bookmarks")
    .then((res) => {
      // Verifica se res é uma string e faz o parse para um objeto
      const bookmarksData = typeof res === 'string' ? JSON.parse(res) : res;

      const courseBookmarks = bookmarksData.courseBookmarks || [];
      const eventBookmarks = bookmarksData.eventBookmarks || [];
      const productBookmarks = bookmarksData.productBookmarks || [];

      this.bookmarks = [...courseBookmarks, ...eventBookmarks, ...productBookmarks];

      const allIds = this.bookmarks.map(bookmark => bookmark.id);
      console.log("Lista de todos os IDs:", allIds);

      const offerId = this.$route.params.offerId;
      this.isFavorite = allIds.includes(offerId);

      // Atribuir this.bookmarks a this.goals
      this.goals = this.bookmarks;
    })
    .catch((error) => {
      console.error("Erro ao obter detalhes da oferta:", error);
    });
},

    toggleFavorite(offerType, offerId) {
      console.log("ToggleFavorite", offerType);

      let favURL = "";

      switch (offerType) {
        case "Course":
          favURL = `/course/bookmark/${offerId}`;
          break;
        case "Event":
          favURL = `/event/bookmark/${offerId}`;
          break;
        case "Product":
          favURL = `/products/bookmark/${offerId}`;
          break;
        default:
          console.error("Tipo de oferta desconhecido:", offerType);
          return;
      }

      let accessToken = localStorage.getItem('accessToken');
      let baseURL = "https://ms-league-az.kemosoft.com.br"
      let requestURL = baseURL + favURL;

      console.log("URL da solicitação:", requestURL);
      console.log(accessToken)

      if (this.isFavorite) {
        this.isFavorite = false;
        axios.delete(requestURL, {},
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          })
          .then((response) => {
            this.$message.success('Conteúdo Removido das Suas Metas!');
          })
          .catch(function (error) {
            this.isFavorite = true;
            console.error("Erro ao remover conteúdo das metas:", error);
          });

      } else {
        this.isFavorite = true;
        axios.post(requestURL, {},
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          })
          .then((response) => {
            this.$message.success('Conteúdo Adicionado às Suas Metas!');
          })
          .catch(function (error) {
            this.isFavorite = false;
            console.error("Erro ao adicionar conteúdo das metas:", error);
          });
      }
    },
  }
}
</script>


<style lang="scss">
.cardDetails {
  width: 100%;
}

.title {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;

  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .title {
    font-size: 16px;
  }
}

/* GRID ROW CARDS */
.content-grid {
  grid-template-columns: repeat(2, 1fr);
}

/* COLUM LEFT */
.col-session:nth-child(1) {

  width: 60%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  .action-icon {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .action-icon:hover {
    transform: scale(1.1);
  }

  .action-icon:active {
    transform: scale(0.7);
  }

  .card-img {
    height: 100%;
    display: flex;
    align-items: end;

    border-radius: 10px;
    overflow: hidden;

    img {
      height: 27.8rem;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 700px) {
    .card-img {
      img {
        height: 20rem;
      }
    }
  }

  @media (max-width: 450px) {
    .card-img {
      img {
        height: 15rem;
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;

    h5 {
      font-size: 14px;
    }

    .share {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 1rem;
      border-radius: 12px;

      .action-title {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .favorite {
      height: 60px;
      width: 60px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-radius: 12px;
    }
  }
}

/* COLUM RIGHT */
.col-session {
  width: 40%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  /* INFORMAÇÕES DE CARD DOS PRODUTOS RIGHT*/
  .infos {
    border: 1px solid #232b3a;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    gap: 12px;

    .icon {
      height: 45px;
      width: 45px;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      .headerTitle {
        font-size: 16px;
        margin: 0;
        padding: 0;
        text-align: left;
      }

      p {
        font-size: 14px;
        margin: 0;
        padding: 0;
        text-align: left;
      }
    }
  }

  .info {
    p {
      margin: 0;
      font-size: 14px;
    }

    h5 {
      margin: 0;
      font-size: 16px;
    }
  }

  /* INFORMAÇÕES IMPORTANTES */
  .price {
    border: 1px solid #232b3a;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .headerTitle {
      margin: 0;
      padding: 0;
      margin-top: 10px;
    }

    .value {
      color: #fff;
      font-size: 40px;
      font-weight: 700;

      span {
        font-size: 20px;
      }
    }
  }

  .buttonStart {
    display: flex;
    width: 100%;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;

    border-radius: 8px;
    background: #3dc790;
    color: #fff;
    font-weight: 700;
    font-size: 16px;

    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02),
      0px 4px 9px 1px rgba(50, 71, 92, 0.04),
      0px 2px 9px 0px rgba(50, 71, 92, 0.06);
  }
}

/* TEMPLATE CURSOS */
.grid-infos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  .info-course {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    border-radius: 12px;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border: 1px solid #232b3a;

    p {
      margin: 0;
      font-size: 12px;
      white-space: nowrap;
    }

    h5 {
      margin: 0;
      font-size: 16px;
    }
  }
}

@media (max-width: 1157px) {
  .grid-infos {
    grid-template-columns: 1fr;
    gap: 10px;

    .info-course {
      padding: 0.7rem;

      p {
        font-size: 10px;
      }

      h5 {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 700px) {
  .grid-infos {
    grid-template-columns: 1fr 1fr;

    .info-course {
      padding: 1rem;

      p {
        font-size: 12px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 700px) {
  .content-grid {
    grid-template-columns: repeat(1, 1fr);

    .col-session:nth-child(1) {
      width: 100%;
    }
  }

  .col-session {
    width: 100%;
  }

  .col-session:nth-child(2) {
    margin-top: 1rem;
  }
}
</style>

