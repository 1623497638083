<template>
  <div>
    <a-drawer :class="{ 'mobile-drawer': isMobile }" :width="drawerWidth" height="100%" :visible="visible" :offerLink="offerLink" :association="association" @close="onClose">
      <div style="font-size: 20px; font-weight: bold;">
        <img src="https://res.cloudinary.com/consigmais/image/upload/v1701435214/assets/n36drdgrb03pilusynwb.png" style="width: 100%;">
      </div>
      <div style="padding: 0 18px 24px 24px;">

        <div style="margin-top: -105px; padding: 15px;">
          <h4 style="line-height: normal;">Indique seus amigos e tenha benefícios exclusivos!</h4>
          <span style="color: aliceblue; line-height: normal;">Participe do programa de indicações e ganhe cashback por
            cada compra efetivada. {{ title }}</span>
        </div>

        <div style="padding: 8px;">

          <a-steps :current="currentStep" style="padding-top: 20px;"></a-steps>

      <a-form :form="form" layout="vertical" hide-required-mark
            style="background-color: #141921; padding: 14px; border-radius: 15px;">

      <div v-if="currentStep === 0">
        <!-- Campos do primeiro passo -->

            <a-row :gutter="24">
              <a-col :span="24">
                 <a-form-item label="Quem você quer indicar?">
                  <a-input v-model="name" v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: 'Preencha com nome da pessoa que você quer indicar' }],
                    },
                  ]" placeholder="Digite o nome da pessoa" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="WhatsApp da Pessoa Indicada">
                  <a-input v-model="phone" v-decorator="[
                    'url',
                    {
                      rules: [
                        { required: true, message: 'Preencha com o Whatsapp da pessoa que você quer indicar' },
                      ],
                    },
                  ]" placeholder="(99) 99999-9999" @input="formatPhoneNumber" />
                </a-form-item>
              </a-col>
            </a-row>

            </div>
            <div v-else>
              <!-- Campos do segundo passo -->
              <a-alert message="Insira o CPF ou CNPJ da pessoa para que possamos bonificar todas as compras vinculadas a esse documento. Caso não tenha essa informação, prossiga mesmo assim." type="info" show-icon />
              <br>
              <a-form-item label="CPF/CNPJ da Pessoa Indicada (Opcional)">
                <a-input v-model="federalId" placeholder="000.000.000-00" v-on:input="formatFederalId"></a-input>
              </a-form-item>
            </div>

          <a-row>
              <a-col>
                <a-button type="primary" @click="handleStepChange" block :loading="isLoadindIndication">
            {{ currentStep === 0 ? 'Continuar' : 'Indicar Pelo WhatsApp' }}
          </a-button>
              </a-col>
            </a-row>
          
          </a-form>

        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: null,
      phone: null,
      federalId: null, // Adicionado novo campo
      isLoadindIndication: false,
      currentStep: 0, // Novo estado para controlar o passo atual
      isMobile: false, // Add a property to track mobile view
      drawerWidth: '500px', // Set the default width for web
    };
  },
  props: {
    visible: Boolean,
    offerLink: String,
    association: String,
    title: String,
  },

  methods: {
    checkMobileView() {
      this.isMobile = window.innerWidth <= 767; // Adjust the breakpoint as needed
      this.drawerWidth = this.isMobile ? '100%' : '500px'; // Set width accordingly
    },
    handleStepChange() {
      if (this.currentStep === 0) {
        if (!this.name || !this.phone) {
          this.$message.warning('Preencha todos os campos antes de continuar.');
          return;
        }
        this.currentStep++;
      } else {
        this.sendIndication();
      }
    },
    formatPhoneNumber() {
      this.phone = this.phone.replace(/\D/g, ''); 
      const formattedPhone = this.phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      this.phone = formattedPhone;
    },
    formatFederalId() {
    this.federalId = this.federalId.replace(/\D/g, ''); 

    if (this.federalId.length === 11) {
      this.federalId = this.federalId.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (this.federalId.length === 14) {
      this.federalId = this.federalId.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  },
    onClose() {
      this.$emit("fecharDrawer");
    },
    sendIndication() {

      if (this.name && this.phone) {
        this.isLoadindIndication = true;

        const form = {
          association: this.association,
          name: this.name,
          whatsapp: this.phone.replace(/\D/g, ''), // Remove caracteres não numéricos
          federalId: this.federalId ? this.federalId.replace(/\D/g, '') : null,
          email: null,
        };

        console.log(form);

        this.$api.dataService.save('indication', form)
          .then((res) => {
            this.isLoadindIndication = false;
            window.location.href = 'https://api.whatsapp.com/send?phone=55' + this.phone.replace(/\D/g, '') + '&text=Ei%20' + this.name.split(' ')[0] + '!%20Tudo%20bem%20por%20a%C3%AD?%20%F0%9F%98%84%20Tenho%20uma%20dica%20imperd%C3%ADvel%20que%20acabei%20de%20descobrir.%20Acredito%20que%20vai%20gostar%20também!%20No%20link%20abaixo,%20você%20encontra%20todos%20os%20detalhes%20%F0%9F%91%87%20em%20%F0%9F%94%97%20' + this.offerLink + '.%20Dá%20uma%20olhada%20quando%20tiver%20um%20tempinho%20livre!%20😊%20Aproveita%C2%A0e%20descobre%20as%20surpresas%20do%20' + this.title + '!';
            this.onClose();
          })
          .catch((err) => {
            this.isLoadindIndication = false;
            this.$message.error('Ops, algo deu errado, tente novamente!');
            console.error(err);
          });
      }else{
        this.$message.warning('Preencha todos os campos antes de continuar.');
      }
 
  },
  },
  created() {
    // Call the method on component creation and window resize
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeDestroy() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener('resize', this.checkMobileView);
  },
};
</script>
<style scoped>
input {
  border-radius: 5px !important;
}

.ant-drawer-title {
  color: #fff !important;
}
</style>